.site-footer {
  background: $hover;
  padding: 30px;
}

.footer-block {
  display: flex;
  align-items: center;
}

.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    color: $body-bg;
    &:hover {
      color: $color;
    }
  }
}

.footer-title {
  font-family: 'Sansumi Regular';
  text-transform: uppercase;
  font-weight: bold;
  font-style: normal;
  font-size: 22px;
  line-height: 1;
  color: $body-bg;
}

.social-link {
  font-size: 22px;
  display: inline-block;
  color: $body-bg;
  margin-left: 10px;
  &:hover {
    color: $color;
  }
}

@media (min-width: 768px) {
  .footer-right {
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {
  .footer-block {
    justify-content: center;
  }
  .footer-left {
    align-items: center;
    margin-bottom: 30px;
  }
  .social-link:first-of-type {
    margin-left: 0;
  }
}

@media (max-width: 575px) {
  .site-footer {
    padding: 30px 0;
  }

  .home-bottom-block p {
    text-align: center;
  }
}