/* This stylesheet generated by Transfonter (https://transfonter.org) on July 31, 2017 4:03 PM */

@font-face {
  font-family: 'Quattrocento Sans';
  src: url('../fonts/QuattrocentoSans-BoldItalic.eot');
  src: url('../fonts/QuattrocentoSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/QuattrocentoSans-BoldItalic.woff2') format('woff2'),
  url('../fonts/QuattrocentoSans-BoldItalic.woff') format('woff'),
  url('../fonts/QuattrocentoSans-BoldItalic.ttf') format('truetype'),
  url('../fonts/QuattrocentoSans-BoldItalic.svg#QuattrocentoSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Quattrocento Sans';
  src: url('../fonts/QuattrocentoSans-Bold.eot');
  src: url('../fonts/QuattrocentoSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/QuattrocentoSans-Bold.woff2') format('woff2'),
  url('../fonts/QuattrocentoSans-Bold.woff') format('woff'),
  url('../fonts/QuattrocentoSans-Bold.ttf') format('truetype'),
  url('../fonts/QuattrocentoSans-Bold.svg#QuattrocentoSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Quattrocento Sans';
  src: url('../fonts/QuattrocentoSans.eot');
  src: url('../fonts/QuattrocentoSans.eot?#iefix') format('embedded-opentype'),
  url('../fonts/QuattrocentoSans.woff2') format('woff2'),
  url('../fonts/QuattrocentoSans.woff') format('woff'),
  url('../fonts/QuattrocentoSans.ttf') format('truetype'),
  url('../fonts/QuattrocentoSans.svg#QuattrocentoSans') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quattrocento Sans';
  src: url('../fonts/QuattrocentoSans-Italic.eot');
  src: url('../fonts/QuattrocentoSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/QuattrocentoSans-Italic.woff2') format('woff2'),
  url('../fonts/QuattrocentoSans-Italic.woff') format('woff'),
  url('../fonts/QuattrocentoSans-Italic.ttf') format('truetype'),
  url('../fonts/QuattrocentoSans-Italic.svg#QuattrocentoSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Sansumi Regular';
  src: url('../fonts/sansumi-regular-webfont.eot');
  src: url('../fonts/sansumi-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/sansumi-regular-webfont.woff') format('woff'),
  url('../fonts/sansumi-regular-webfont.ttf') format('truetype'),
  url('../fonts/sansumi-regular-webfont.svg#sansumi_regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sansumi Bold';
  src: url('../fonts/sansumi-bold-webfont.eot');
  src: url('../fonts/sansumi-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/sansumi-bold-webfont.woff') format('woff'),
  url('../fonts/sansumi-bold-webfont.ttf') format('truetype'),
  url('../fonts/sansumi-bold-webfont.svg#sansumi_bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

