.site-header {
  background: $bg-dark;
  padding: 30px 0;

  .logo-wrap {
    height: 140px;
    text-align: center;
    display: block;
    img {
      height: 100%;
      width: auto;
    }
  }

  h2 {
    font-size: 22px;
    line-height: 28px;
    font-family: 'Sansumi Regular';
    text-transform: uppercase;
    font-weight: normal;
    font-style: normal;
    color: $body-bg;
  }
}