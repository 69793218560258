// Fonts
//$font: 'Quattrocento Sans', sans-serif;
//$bold-font: 'Ubuntu', sans-serif;

$hover: #f57636;
$color: #444;
$bg-dark: #45434C;
$body-bg: #fbfbfb;


@mixin trns {
  transition:  all 0.5s ease;
}

html, body {
  font-size: 14px;
  line-height: 1.428571429;
  margin: 0;
}

body {
  min-height: 100vh;
  background: $body-bg;
  color: $color;
  overflow-x: hidden;
}

ul, ol, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a, a:hover, a:link, a:visited {
  cursor: pointer;
  outline: none;
  text-decoration: none;
  @include trns;
}

.df {
  display: flex;
  flex-flow: wrap;
}

.tac {
  text-align: center;
}

.ltsp {
  letter-spacing: 4px;
}

.image-wrapper img {
  width: 100%;
  max-width: 100%;
  height: auto;
}