.home-page {
  img {
    margin-bottom: 20px;
  }
}

.home-gallery {
  padding-top: 30px;
}

.images-row {
  flex-direction: column;
  justify-content: space-between;
}

.image-wrapper {
  display: block;
  height: auto;
}

.home-bottom-block {
  padding: 30px 0;
  p {
    font-family: 'Sansumi Regular';
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    margin: 0 0 70px;
  }
  p:first-child {
    margin-bottom: 30px;
  }
}